@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --border-radius: var(--mui-shape-borderRadius);
  --border-color: var(--mui-palette-divider);
  --primary-color: var(--mui-palette-primary-main);
  --background-color: var(--mui-palette-background-default);
  --background-color-rgb: var(--mui-palette-background-paperChannel);
  --header-height: 64px;
  --header-z-index: var(--mui-zIndex-appBar);
  --footer-z-index: 10;
  --customizer-z-index: var(--mui-zIndex-drawer);
  --search-z-index: var(--mui-zIndex-tooltip);
  --drawer-z-index: var(--mui-zIndex-drawer);
  --backdrop-color: rgb(var(--mui-mainColorChannels-light) / 0.5);
}

[data-mui-color-scheme='dark'] {
  --backdrop-color: rgb(21 16 43 / 0.6);
}

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT', currentColor);
}

html {
  display: flex;
  inline-size: 100%;
  min-block-size: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

ul:not([class]) {
  margin-block: 1rem;
  padding-inline-start: 40px;
}
